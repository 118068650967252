<template>
	<div class="vg_wrapper">
		<add-header
				@submit="submit('matrForm')"
				@cancel="cancel"/>
		<el-form ref="matrForm" :rules="rules" :model="matrForm" label-width="120px" size="mini">
			<el-row class="vg_mb_5">
				<el-col :md="8">
					<el-form-item label="采购方名称" prop="puca_no">
						<el-select v-model="matrForm.cptt_name" placeholder="请选择采购方" clearable :disabled="true">
							<el-option
									v-for="item in cpptList"
									:key="item.cptt_id"
									:label="item.cptt_name"
									:value="item.cptt_id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="材料采购号" prop="modr_no">
						<el-input v-model="matrForm.modr_no" clearable  show-word-limit :disabled="true"/>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="成品采购合同号" prop="podr_no">
						<el-input v-model="matrForm.podr_no" clearable show-word-limit :disabled="true"/>
					</el-form-item>
				</el-col>
			</el-row >
			<el-row class="vg_mb_5">
				<el-col :md="8">
					<el-form-item label="供应商名称" prop="supp_name">
						<el-input v-model="matrForm.supp_name" clearable maxlength="30"  show-word-limit placeholder="请填写供应商名称" :disabled="true"/>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="最终客户" prop="cust_abbr">
						<el-select size="small" filterable v-model="matrForm.cust_abbr" placeholder="请选择客户简称" :disabled="true">
							<el-option v-for="item in custList" :key="item.cust_abbr" :label="item.cust_abbr"
							           :value="item.cust_abbr">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="采购经办人" prop="cust_abbr">
						<el-select size="small" filterable v-model="matrForm.cust_abbr" placeholder="请选择客户简称" :disabled="true">
							<el-option v-for="item in custList" :key="item.cust_abbr" :label="item.cust_abbr"
							           :value="item.cust_abbr">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="vg_mb_5">
				<el-col :md="8">
					<el-form-item label="计划付款日期" prop="paym_date">
						<el-date-picker
								v-model="matrForm.paym_date"
								type="date" :disabled="true"
								placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="要求交货日期" prop="deli_date">
						<el-date-picker
								v-model="matrForm.deli_date"
								type="date" :disabled="true"
								placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="采购日期" prop="puca_date">
						<el-date-picker
								v-model="matrForm.puca_date"
								type="date" :disabled="true"
								placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="vg_mb_5">
				<el-col :md="24" class="vg_mb_5">
					<el-form-item label="收货地址" prop="puca_addr">
						<el-input v-model="matrForm.puca_addr" clearable maxlength="30"  show-word-limit placeholder="请填写收货信息"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :md="24" class="vg_mb_5">
					<el-form-item label="订单备注" prop="puca_remark">
						<el-input type="textarea" :rows="3" v-model="matrForm.puca_remark" clearable show-word-limit placeholder="请填写备注"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="vd_mrt vg_mb_5">
				<MatrAddProd :isShow="false" @handleSelectionChange="handleSelect" :matrForm="matrForm"></MatrAddProd>
			</el-row>
			<el-dialog
					:title="title"
					:visible.sync="notice"
					width="70%">
				<MatrAddEditIn @cancelPodrIn="cancelPodrIn" @confirmIn="confirmIn" ref="PodrAddEditIn"></MatrAddEditIn>
			</el-dialog>
			<el-row class="vg_mt_16 vd_bortop">
				<el-col>
					<inputUser :isCookie="true" ref="userData"></inputUser>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import {cpttAPI} from "@api/modules/comptitle";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import MatrAddProd from "@/views/PurcManagement/MatrManage/TabChild/Compont/TabPanel/MatrAddProd"
import MatrAddEditIn from "@/views/PurcManagement/MatrManage/TabChild/Compont/TabPanel/MatrAddEditIn"
import {matrAPI} from "@api/modules/matr";
import {custAPI} from "@api/modules/cust";

export default {
	name: "MatrAddMain",
	components: {
		addHeader,
		inputUser,
		MatrAddProd,
		MatrAddEditIn
	},
	data() {
		return {
			rules: {
				cptt_id: [{required: true, trigger: 'change', message: ' '},],
				modr_no: [{required: true, trigger: 'change', message: ' '},],
				podr_no: [{required: true, trigger: 'change', message: ' '},],
				supp_name: [{required: true, trigger: 'change', message: ' '},],
				puca_date: [{required: true, trigger: 'blur', message: ' '},],
				paym_date: [{required: true, trigger: 'blur', message: ' '},],
				deli_date: [{required: true, trigger: 'blur', message: ' '},],
				puca_addr: [{required: true, trigger: 'blur', message: ' '},],
				cust_abbr: [{required: true, trigger: 'blur', message: ' '},]
			},
			matrForm: {
				puca_mtrb_list: [],
				supp_id: 0,
			},
			notice: false,
			podrProdLi: [],
			removePodrProdLi: [],
			cpptList: [],
			cpttFctrGroupOpt: [],
			title: '产品信息表',
			specialDataFlag: false,
			custList: [],
			isReissue: false,
		};
	},
	watch: {
		// 下拉选择框选择补全id
		'matrForm.cust_abbr'(newValue, oldValue) {
			if (newValue) {
				setTimeout(() =>{
					let find = this.custList.find(x => x.cust_abbr === newValue);
					this.matrForm.cust_inspstand = find.cust_inspstand
					this.matrForm.cust_id = find.cust_id
				},500)
			}
		},
		'matrForm.cptt_name'(newValue, oldValue) {
			if (newValue) {
				setTimeout(() => {
					let find = this.cpptList.find(x => x.cptt_name === newValue);
					this.matrForm.cptt_id = find.cptt_id
				}, 500)
			}
		}
	},
	created() {
		this.initData()
		
	},
	mounted() {
		this.isReissue = this.$route.query.isReissue
	},
	methods: {
		// 获取客户
		getCustList() {
			get(custAPI.getCustFromErp, {}).then((res) => {
				if (res.data.code === 0) {
					this.custList = res.data.data.list
				}
			});
		},
		initData() {
			this.getCppt()
			this.getCustList()
			this.getFromData();
			if (this.$cookies.get('userInfo').acct_id === 1) {
				this.specialDataFlag = true
			}
		},
		getFromData() {
			get(matrAPI.getPucaById, {puca_id: this.$route.query.form_id}).then(res => {
				if (res.data.code === 0) {
					// 权限按钮
					this.btn = res.data.data.btn;
					this.matrForm = res.data.data.form;
					this.matrForm.puca_date = this.matrForm.puca_date ? this.matrForm.puca_date * 1000 : null;
					this.matrForm.paym_date = this.matrForm.paym_date ? this.matrForm.paym_date * 1000 : null;
					this.matrForm.deli_date = this.matrForm.deli_date ? this.matrForm.deli_date * 1000 : null;
					this.matrAcctCope = JSON.parse(JSON.stringify(this.matrForm.puca_mtrb_list))
					this.matrForm.matr_total = this.helper.haveFour(this.matrForm.matr_total)
					this.matrForm.extra_price = this.helper.haveFour(this.matrForm.extra_price)
					this.matrForm.puca_mtrb_list.forEach(item => {
						if (item.puca_mtrb_price) {
							item.puca_mtrb_price = this.helper.haveFour(item.puca_mtrb_price)
							item.puca_mtrb_total = this.helper.haveFour(item.puca_mtrb_total)
						}
					});
					setTimeout(() => {
						this.loadingFlag = false
					}, 500);
				}
			})
		},
		//提交表单
		submit(formName) {
			this.$confirm('确认提交?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.saveInfo()
					} else {
						console.log('error submit!');
						return false;
					}
				});
			}).catch(() => {
			})
		},
		// 保存
		saveInfo() {
			let matrForm = {};
			matrForm = Object.assign(matrForm, this.matrForm);
			matrForm.puca_date = (new Date(this.matrForm.puca_date)).getTime() / 1000;
			matrForm.paym_date = (new Date(this.matrForm.paym_date)).getTime() / 1000;
			matrForm.deli_date = (new Date(this.matrForm.deli_date)).getTime() / 1000;
			const staffForm = this.$refs.userData.getData()
			matrForm = Object.assign(matrForm, staffForm)
			post(matrAPI.addPuca, matrForm).then(res => {
				if (res.data.code === 0) {
					this.$message({type: 'success', message: '保存成功'})
					const permId = this.$route.query.perm_id
					this.jump(`/puca_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
					this.resetForm('matrForm')
				}
			});
		},
		//取消
		cancel() {
			this.$confirm('确定取消新增?', '提示', {
				confirmButtonText: '是',
				cancelButtonText: '否',
				type: 'warning'
			}).then(() => {
				this.resetForm('matrForm')
				this.$router.go(-1)
				this.$message({
					type: 'info',
					message: '已取消新增!'
				});
			})
		},
		//删除选择的内容
		handleSelect(val) {
			this.removePodrProdLi = val;
		},
		// 删除产品信息按钮
		delBank() {
			if (this.matrForm.puca_mtrb_list.length <= 0) {
				return this.$message.warning('至少选择一条数据')
			}
			// 删除选中数据
			let set = new Set(this.matrForm.puca_mtrb_list);
			this.removePodrProdLi.forEach((item) => set.delete(item))
			this.matrForm.puca_mtrb_list = Array.from(set);
			
		},
		addNullRow() {
			this.matrForm.puca_mtrb_list.push({
				prod_no: null,
				prod_name: null,
				cust_artno: null,
				mtrb_name: null,
				mtrb_no: null,
				mtrb_spec: null,
				puca_mtrb_num: null,
				puca_mtrb_cnum: null,
				puca_mtrb_unit: null,
				puca_mtrb_price: null,
				puca_mtrb_total: null,
			})
		},
		// 打开导入产品信息小窗口
		addRow() {
			this.notice = true;
		},
		// 导入产品信息小窗口确定按钮
		confirmIn(val) {
			this.matrProdLi = val;
			this.notice = false;
			this.matrProdLi = this.matrProdLi.filter(item => this.matrForm.puca_mtrb_list.every(item1 => item.puca_id !== item1.puca_id));
			this.matrForm.puca_mtrb_list = this.matrForm.puca_mtrb_list.concat(this.matrProdLi);
			this.$refs.PodrAddEditIn.clear()
		},
		// 取消选择
		cancelPodrIn() {
			this.notice = false
		},
		// 获取合作单位信息
		getCppt() {
			get(cpttAPI.getAllCpttsV1,{}).then(res => {
				if (res.data.code === 0) {
					this.cpptList = res.data.data;
				}
			})
		},
		//表单重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.matrForm.puca_mtrb_list = [];
		},
	}
};
</script>

<style scoped lang="scss">
.vd_bortop {
	border-top: #DCDFE6 solid 1px;
	padding-top: 20px;
}
.vd_mrt{
	margin-top: 20px;
}
.vd_eltab {
	margin-top: 10px;
}
</style>