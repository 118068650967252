<template>
	<div>
		<el-table highlight-current-row
		          ref="multiTable"
		          :data="matrForm.puca_mtrb_list"
		          border
		          @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="48" align="center"/>
			<el-table-column label="编号" width="48" align="center">
				<template v-slot="scope">
					{{ scope.$index+1 }}
				</template>
			</el-table-column>
			<el-table-column v-if="this.$route.query.batch">
				<template slot="header">
					<span class="vg_deep_red">辅料采购单号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.modr_no'">
						<el-input show-word-limit maxlength="30" :disabled="batch"
						          v-model="matrForm.puca_mtrb_list[scope.$index].modr_no"
						          placeholder="暂无"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column v-if="this.$route.query.batch">
				<template slot="header">
					<span class="vg_deep_red">成品采购合同号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.podr_no'">
						<el-input show-word-limit maxlength="30" :disabled="batch"
						          v-model="matrForm.puca_mtrb_list[scope.$index].podr_no"
						          placeholder="暂无"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column>
				<template slot="header">
					<span class="vg_deep_red">客户货号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.cust_artno'" :rules="[{required:true}]">
						<el-input show-word-limit maxlength="30" :disabled="true"
						          v-model="matrForm.puca_mtrb_list[scope.$index].cust_artno"
						          placeholder="暂无货号"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column>
				<template slot="header">
					<span class="vg_deep_red">材料名称</span>
				</template>
				<template v-slot="scope">
					<el-button type="text" v-if="!matrForm.puca_mtrb_list[scope.$index].mtrb_name" @click="showDialog(scope.$index)">点击选择</el-button>
					<el-form-item v-else label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.mtrb_name'" :rules="[{required:true}]">
						<el-input :disabled="true"
						          v-model="matrForm.puca_mtrb_list[scope.$index].mtrb_name"
						          maxlength="11"
						          show-word-limit
						          placeholder="暂无名称"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column>
				<template slot="header">
					<span class="vg_deep_red">材料编号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.mtrb_no'" :rules="[{required:true}]">
						<el-input :disabled="true"
						          v-model="matrForm.puca_mtrb_list[scope.$index].mtrb_no"
						          show-word-limit
						          placeholder="暂无编号"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="材料规格">
				<template slot="header">
					<span class="vg_deep_red">材料规格</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.mtrb_spec'"  :rules="[{required:true}]">
						<el-input :disabled="true"
						          v-model="matrForm.puca_mtrb_list[scope.$index].mtrb_spec"
						          show-word-limit
						          placeholder="暂无规格"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="材料数量">
				<template slot="header">
					<span class="vg_deep_red">材料数量</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.puca_mtrb_num'" :rules="[{required:true}]">
						<el-input v-model="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_num" :disabled="!(isReissue === 'reissue' || isEdit === 'edit') || matrForm.puca_status === 1"
						          show-word-limit
						          placeholder="暂无数量"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="材料图片">
				<template slot="header">
					<span class="vg_deep_red">材料图片</span>
				</template>
				<template v-slot="scope">
					<el-upload v-if="!matrForm.puca_mtrb_list[scope.$index].imge_url" class="upload-demo"
					           :action="getUploadUrl" :before-upload="beforeUpload" :data="dataBody" :disabled="true"
					           :show-file-list="false" :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, scope.$index)">
						<el-button type="text" size="mini" :disabled="true" >点击上传</el-button>
					</el-upload>
					<div v-else style="display: flex;justify-content: center">
						<el-popover placement="left" width="400" trigger="hover">
							<el-image style="height: 400px" :src="helper.picUrl(matrForm.puca_mtrb_list[scope.$index].imge_url,'s','',0)"/>
							<el-form-item slot="reference"
							              label-width="0"
							              :prop="'puca_mtrb_list.'+scope.$index+'.imge_url'" :rules="[{required:true}]"
							              style="height: 50px;width: 50px">
								<el-image style="height: 50px" :src="helper.picUrl(matrForm.puca_mtrb_list[scope.$index].imge_url,'s','',0)"/>
							</el-form-item>
						</el-popover>
						<el-button type="text" size="mini" style="width:30%" @click="clearImage(scope.$index)" :disabled="true">清空</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="变更数量"  v-if="!(isReissue === 'reissue' || isEdit === 'edit')">
				<template slot="header">
					<span class="vg_deep_red">变更数量</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.puca_mtrb_cnum'">
						<el-input :disabled="!(isChange === 'change')"
											v-model="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_cnum"
						          show-word-limit style="color: red !important;"
						          placeholder="暂无数量"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="单位">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.puca_mtrb_unit'">
						<el-input v-model="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_unit"
						          :disabled="true"
						          maxlength="10"
						          show-word-limit
						          placeholder="暂无单位"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="单价">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.puca_mtrb_price'">
						<el-input @blur="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_price = helper.calcPrice(matrForm.puca_mtrb_list[scope.$index].puca_mtrb_price,4,10000)"
						          v-model="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_price"
						          maxlength="9" :disabled="true"
						          show-word-limit
						          placeholder="暂无单价"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="金额">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.puca_mtrb_total'">
						<el-input v-model="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_total"
						          @blur="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_price = helper.calcPrice(matrForm.puca_mtrb_list[scope.$index].puca_mtrb_price,4,1000000000000)"
						          maxlength="17" :disabled="true"
						          show-word-limit
						          placeholder="暂无金额"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="备注">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.puca_mtrb_remark'">
						<el-input v-model="matrForm.puca_mtrb_list[scope.$index].puca_mtrb_remark"
						          show-word-limit
						          placeholder="暂无备注"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="要求交货日期">
				<template slot="header"><span>要求交货日期</span></template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'puca_mtrb_list.'+scope.$index+'.deli_date'">
						<el-date-picker v-model="matrForm.puca_mtrb_list[scope.$index].deli_date"
						                type="date" :disabled="true"
						                placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</template>
			</el-table-column>
		</el-table>
		<!--材料弹窗-->
		<el-dialog
				title="材料"
				:visible.sync="dialogVisible"
				width="70%">
			<el-form :model="searchForm" :inline='true' ref="searchForm" label-width="120px" size="mini">
				<el-row>
					<el-col :md="8">
						<el-form-item label="材料编号" prop="mtrb_no">
							<el-input v-model="searchForm.mtrb_no" placeholder="请输入材料编号"  size="small" ></el-input>
						</el-form-item>
					</el-col>
					<el-col :md="8">
						<el-form-item label="材料类型" prop="mtrb_type">
							<el-select size="small" v-model="searchForm.mtrb_type" clearable placeholder="请选择材料类型">
								<el-option v-for="item in mtrbTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :md="8">
						<el-form-item label="供应商简称" prop="supp_id">
							<el-select size="small" filterable v-model="searchForm.supp_id" clearable placeholder="请选择供应商简称">
								<el-option v-for="item in suppList" :key="item.supp_id" :value="item.supp_id" :label="item.supp_abbr"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="vg_mt_8">
					<el-col :md="12">
						<el-form-item label="材料名称" prop="mtrb_name">
							<el-input size="small" v-model="searchForm.mtrb_name" placeholder="请输入材料名称"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button size="small" type="primary" icon="el-icon-search" @click="getQutbNow()" class="vg_ml_16">查询
							</el-button>
							<el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i
									class="el-icon-refresh-right"></i> 刷新</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-row class="vg_mt_16">
				<el-col :md="16">
					<el-button size="mini" type="danger" @click="clickCancel">取消选择</el-button>
				</el-col>
			</el-row>
			<el-row  class="vg_mt_16">
				<el-col :md="24">
					<el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChangeImport" :row-key="getRowKey" @row-dblclick="dbClick" border v-loading="loading">
						<el-table-column label="材料编号" prop="mtrb_no" align="center"></el-table-column>
						<el-table-column label="材料类型" prop="mtrb_type" align="center" :formatter="getMtrbType"/>
						<el-table-column label="材料名称" prop="mtrb_name" align="center"></el-table-column>
						<el-table-column label="中文规格" prop="mtrb_spec" align="center"></el-table-column>
						<el-table-column label="供应商简称" prop="supp_abbr" align="center"></el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row class="vg_mt_16">
				<el-col :md="24">
					<pubPagination :totalPage='total' @changePageSearch="changePageSearch" ref="pubPagination"/>
				</el-col>
			</el-row>
		
		</el-dialog>
	</div>
</template>

<script>
import {get} from "@api/request";
import {optnAPI} from "@api/modules/optn";
import {mtrbAPI} from "@api/modules/mtrb";
import {suppAPI} from "@api/modules/supp";
import pubPagination from "@/components/common/pubPagination";
import {imgeAPI} from "@api/modules/imge";

export default {
	name: "MatrAddProd",
	components: {pubPagination},
	props: {
		matrForm: {
			type: Object,
			required: true,
		},
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		getUploadUrl() {
			return this.helper.modePrepend(imgeAPI.uploadImage)
		}
	},
	data() {
		return {
			dataBody: {
				file: /\.(png|jpg|gif|jpeg)$/,
				cut: true,
				keep: false,
				x: 400,
				y: 400,
			},
			loading: true,
			mtrbTypeList:[
				{id:1,label:'辅料'},
				{id:2,label:'包材'},
			],
			total: 0,
			searchForm: {
				page_no: 1,
			},
			dialogVisible: false,
			selectionsList: [],
			tableHeight: 269,
			prodTypeGroupOpt: [],
			tableData: [],
			suppList: [],
			currentIndex: 0,
			isReissue: '',
			isEdit: '',
			isChange: '',
			
		};
	},
	created() {
		this.getMtrb();
		this.batch = Boolean(this.$route.query.batch)
	},
	mounted() {
		this.isReissue = this.$route.query.isReissue
		this.isEdit = this.$route.query.isEdit
		this.isChange = this.$route.query.isChange
	},
	methods: {
		clearImage(index) {
			this.matrForm.puca_mtrb_list[index].imge_url = "";
		},
		handleSuccess(res, file, fileList, index) {
			if (res.code === 0) {
				this.matrForm.puca_mtrb_list[index].imge_url = res.data.imge_url;
			} else {
				this.$message({message: res, type: 'error'});
			}
		},
		beforeUpload(res) {
			const isLt5M = res.size / 1024 / 1024 < 10
			if (!isLt5M) {
				this.$message.error('图片大小不得超过 10MB!');
				return false
			}
		},
		getDisabled() {
			return this.$route.query.type === '0'
		},
		getSupp() {
			get(suppAPI.getSuppsV1).then(res => {
				if (res.data.code === 0) {
					this.suppList = res.data.data
					return
				}
				this.$message.error(res.data.msg)
			}).catch((res) => {
				this.$message.error(res.data.msg)
			})
		},
		dbClick(row) {
			this.matrForm.puca_mtrb_list[this.currentIndex].mtrb_name = row.mtrb_name
			this.matrForm.puca_mtrb_list[this.currentIndex].mtrb_id = row.mtrb_id
			this.matrForm.puca_mtrb_list[this.currentIndex].mtrb_no = row.mtrb_no
			this.matrForm.puca_mtrb_list[this.currentIndex].mtrb_spec = row.mtrb_spec
			this.matrForm.puca_mtrb_list[this.currentIndex].imge_url = row.imge_url
			this.$refs.multiTable.clearSelection()
			this.dialogVisible = false
		},
		//取消选择
		clickCancel() {
			this.$refs.multiTable.clearSelection()
		},
		showDialog(index) {
			this.currentIndex = index
			this.dialogVisible = true;
		},
		changePageSearch(val) {
			this.searchForm.page_no = val
			this.getMtrb()
		},
		getMtrbType(row) {
			if (row.mtrb_type === 1) {
				return '辅料'
			} else {
				return '包材'
			}
		},
		getRowKey(row) {
			return row.mtrb_id
		},
		handleSelectionChangeImport(select) {
			this.selectTab = select
		},
		getMtrb() {
			get(mtrbAPI.getAllMtrbsV1, {
				page_no: this.searchForm.page_no,
				mtrb_no: this.searchForm.mtrb_no,
				mtrb_type: this.searchForm.mtrb_type,
				mtrb_name: this.searchForm.mtrb_name,
				supp_id: this.searchForm.supp_id,
			}).then(res => {
				if (res.data.code === 0) {
					this.tableData = res.data.data.list
					this.total = res.data.data.total
					setTimeout(() => {this.loading = false},500)
					return
				}
				this.$message.error(res.data.msg)
			}).catch(res => {
				this.$message.error(res.data.msg)
			})
		},
		buttonRefresh() {
			this.$refs.searchForm.resetFields()
			this.searchForm.page_no = 1
			this.$refs.pubPagination.currentPage = 1
			this.getMtrb()
		},
		getQutbNow() {
			this.searchForm.page_no = 1
			this.$refs.pubPagination.currentPage = 1
			this.getMtrb()
		},
		// 清空
		clear() {
			this.$refs.multiTable.clearSelection();
			this.multiSelection = null
		},
		//产品信息选择框
		handleSelectionChange(selection) {
			this.selectionsList = [];
			this.selectionsList = selection
			this.$emit("handleSelectionChange", this.selectionsList)
		},
	},
	
};
</script>

<style scoped lang="scss">
::v-deep .el-upload--text {
	width: 100%;
	height: 100%;
}
</style>>
