<template>
    <div class="vd_search_group vg_mtb_16">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getProdsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="外销合同号:">
              <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写外销合同号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="采购合同号:">
              <el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写采购合同号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="客户货号:">
              <el-input size="small" v-model.trim="searchForm.cust_artno" clearable placeholder="请填写客户货号"></el-input>
            </el-form-item>
          </el-col>
	        <el-col :md="24">
		        <el-button size="small" type="primary" icon="el-icon-search" @click="getProdsNow()">查询</el-button>
		        <el-button size="small" type="info" icon="el-icon-refresh" @click="getProdsNowO()">刷新</el-button>
	        </el-col>
        </el-row>
      </el-form>
      <el-row class="vd_mar15">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-row> 
      <el-row class="vd_mar15">
        <div><i class="el-icon-info vg_mr_8"></i>{{title}}</div>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    :row-key="getRowKey"
                    border
                    v-loading="loadingFlag">
            <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/>
            <el-table-column label="外销合同号" prop="modr_no"/>
            <el-table-column label="成品采购合同号" prop="podr_no" />
            <el-table-column label="客户货号" prop="cust_artno" />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
    </div>
</template>

<script>
import {get} from "@api/request";
import {prodAPI} from "@api/modules/prod"
import options from "@/views/component/common/options";
import pubPagination from "@/components/common/pubPagination";
import {matrAPI} from "@api/modules/matr";

export default {
	name: "PurcEditIn",
	components: {
		pubPagination,
	},
	props: {},
	data() {
		return {
			tableData: [],
			searchForm: {
				page_no: 1
			},
			totalPage: 0,
			btn: {},
			loading: true,
			multiSelection: [],
			currentPage: 1,
			flag: false,
			prodTypeGroupOpt: [],
			loadingFlag: true,
			title: '提示：相同数据仅限导入一条',
		};
	},
	created() {
		this.initData()
	},
	methods: {
		initData() {
			this.loadingFlag = true
			this.getProdsList()
			this.mtrlTypeGroupCrft = options.mtrlUnit;
		},
		// 获取样品信息
		getProdsList() {
			const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
			get(matrAPI.getAllPucaMtrb, this.searchForm).then(res => {
						if (res.data.code === 0) {
							this.tableData = res.data.data.list;
							this.totalPage = res.data.data.total
							this.btn = res.data.data.btn;
							setTimeout(() => {
								this.loadingFlag = false
							}, 500);
						} else {
							this.$message({message: res.data.msg, type: 'error'});
						}
					}).catch(res => {
						this.$message({message: res, type: 'error'});
					})
		},
		// 时间转换
		formatDate(row) {
			return this.helper.toStringDate(row.create_time)
		},
		// 获取样品状态
		getProdType(flag) {
			if (flag === true && this.prodTypeGroupOpt.length === 0) {
				this.prodTypeGroupOpt = options.smplStatus
			}
		},
		//样品状态
		formatProdType(row) {
			if (row.status === 0) {
				return '草拟'
			} else if (row.status === 1) {
				return '在批'
			} else if (row.status === 2) {
				return '生效'
			}
		},
		// 查询方法
		getProdsNow() {
			this.$refs.pubPagination.currentPage = 1
			this.currentPage = 1
			this.initData()
		},
		// 刷新
		getProdsNowO() {
			this.$refs.pubPagination.currentPage = 1
			this.currentPage = 1
			this.searchForm = {
				prod_no: '',
				prod_name: null,
			}
			this.initData()
		},
		// 清空
		clear() {
			this.$refs.multiTable.clearSelection();
		},
		// 指定一个key标识这一行的数据
		getRowKey(row) {
			return row.prod_id;
		},
		// 多选
		handleSelectionChange(val) {
			this.multiSelection = val;
		},
		// 取消选择
		cancel() {
			this.$emit("cancelPodrIn")
			this.clear()
		},
		// 确认选择
		confirmIn() {
			if (this.multiSelection.length > 0) {
				this.$emit("confirmIn", this.multiSelection)
			} else {
				this.$message.warning("至少选择一条数据")
			}
			
		},
		// 分页查询
		changePageSearch(val) {
			this.currentPage = val
			this.initData()
		},
	}
};
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_mar15{
  margin: 15px 0;
}
</style>
